export const formatCNPJ = (value) => {
  if (typeof value !== "string") {
    return value;
  }

  return value.replace(/\D/, "").padStart(14, '0').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

export const formatCPF = (value) => {
  if (typeof value !== "string") {
    return value;
  }

  return value.replace(/\D/, "").padStart(11, '0').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};
