import Vue from "vue";
import Vuetify from "vuetify/lib";
import pt from "vuetify/es5/locale/pt";
import UploadFotoIcon from "@/icons/upload-foto.vue";
import GalaposG from "@/icons/galapos-g.vue";
import ChatIcon from "@/icons/chat-icon.vue";
import CadastroIcon from "@/icons/cadastro-icon.vue";
import ColaboradoresIcon from "@/icons/colaboradores-icon.vue";
import ProjetosIcon from "@/icons/projetos-icon.vue";
import DispendiosIcon from "@/icons/dispendios-icon.vue";
import TimesheetIcon from "@/icons/timesheet-icon.vue";
import FaturamentoIcon from "@/icons/faturamento-icon.vue";
import RelatoriosIcon from "@/icons/relatorios-icon.vue";
import ImportacoesIcon from "@/icons/importacoes-icon.vue";
import TerceirosIcon from "@/icons/terceiros-icon.vue";
import MateriaisIcon from "@/icons/materiais-icon.vue";

Vue.use(Vuetify);

function icon(name, component) {
  var def = {};
  def[name] = {
    component: component,
    props: {
      color: "#1C1C44",
    },
  };
  def[name + "Cli"] = {
    component: component,
    props: {
      color: "#0B5456",
    },
  };
  return def;
}

export default new Vuetify({
  theme: {
    default: 'galapos',
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        error: "#b71c1c",

        primary: "#1C1C44", // #E53935
        secondary: "#1E88E5",
        accent: "#D84315",
        'table-header': "#BEDEFC",
        'table-header-highlight': "#CEE7E7",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    values: {
      uploadfoto: {
        component: UploadFotoIcon,
      },
      galaposG: {
        component: GalaposG,
      },
      chatIcon: {
        component: ChatIcon,
      },
      ...icon("cadastroIcon", CadastroIcon),
      ...icon("colaboradoresIcon", ColaboradoresIcon),
      ...icon("projetosIcon", ProjetosIcon),
      ...icon("dispendiosIcon", DispendiosIcon),
      ...icon("timesheetIcon", TimesheetIcon),
      ...icon("faturamentoIcon", FaturamentoIcon),
      ...icon("relatoriosIcon", RelatoriosIcon),
      ...icon("importacoesIcon", ImportacoesIcon),
      ...icon("terceirosIcon", TerceirosIcon),
      ...icon("materiaisIcon", MateriaisIcon),
    },
  },
});
