<template>
  <svg viewBox="1 2 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7901 18.8711C15.5253 18.6015 15.2103 18.3877 14.8633 18.2421C14.5164 18.0964 14.1444 18.0218 13.7687 18.0225H9.70668C9.33091 18.0225 8.95884 18.0974 8.61171 18.243C8.26458 18.3885 7.9492 18.6019 7.68359 18.8708C7.41799 19.1398 7.20737 19.4591 7.06377 19.8105C6.92017 20.1618 6.8464 20.5384 6.84668 20.9186V21.9456C6.84668 22.1684 6.93413 22.382 7.0898 22.5395C7.24546 22.697 7.45658 22.7855 7.67672 22.7855C7.89687 22.7855 8.10799 22.697 8.26365 22.5395C8.41932 22.382 8.50677 22.1684 8.50677 21.9456V20.9186C8.50648 20.7591 8.53733 20.601 8.59753 20.4536C8.65773 20.3062 8.7461 20.1722 8.85758 20.0594C8.96906 19.9466 9.10145 19.8572 9.24716 19.7963C9.39286 19.7354 9.54902 19.7041 9.70668 19.7044H13.7623C14.0805 19.7044 14.3857 19.8324 14.6107 20.06C14.8358 20.2877 14.9622 20.5966 14.9622 20.9186V21.9456C14.9622 22.1684 15.0496 22.382 15.2053 22.5395C15.361 22.697 15.5721 22.7855 15.7922 22.7855C16.0124 22.7855 16.2235 22.697 16.3792 22.5395C16.5348 22.382 16.6223 22.1684 16.6223 21.9456V20.9186C16.6246 20.5385 16.5522 20.1618 16.4093 19.8103C16.2664 19.4588 16.056 19.1395 15.7901 18.8711Z"
      :fill="color"
    />
    <path
      d="M11.7369 16.7781C12.3026 16.7781 12.8556 16.6084 13.3259 16.2904C13.7962 15.9724 14.1628 15.5204 14.3792 14.9916C14.5957 14.4628 14.6523 13.881 14.542 13.3196C14.4316 12.7582 14.1592 12.2426 13.7593 11.8379C13.3593 11.4331 12.8497 11.1575 12.2949 11.0458C11.7401 10.9342 11.1651 10.9915 10.6425 11.2105C10.1199 11.4296 9.67321 11.8005 9.35895 12.2764C9.04469 12.7523 8.87695 13.3118 8.87695 13.8842C8.87809 14.6513 9.17978 15.3868 9.71588 15.9292C10.252 16.4717 10.9788 16.777 11.7369 16.7781ZM10.537 13.8842C10.537 13.644 10.6074 13.4093 10.7393 13.2096C10.8711 13.01 11.0585 12.8543 11.2778 12.7624C11.497 12.6706 11.7383 12.6465 11.971 12.6934C12.2038 12.7402 12.4176 12.8558 12.5854 13.0256C12.7532 13.1954 12.8675 13.4118 12.9138 13.6473C12.9601 13.8828 12.9363 14.127 12.8455 14.3488C12.7547 14.5707 12.6009 14.7603 12.4036 14.8937C12.2063 15.0271 11.9743 15.0983 11.7369 15.0983C11.4187 15.0983 11.1135 14.9704 10.8885 14.7427C10.6635 14.515 10.537 14.2062 10.537 13.8842Z"
      :fill="color"
    />
    <path
      d="M22.8114 2.4629H9.71346C9.26517 2.46241 8.82386 2.57519 8.42957 2.79102C8.03527 3.00684 7.70048 3.31888 7.45557 3.69881L6.90723 4.61921L9.71346 4.59093H22.8114C22.9659 4.59093 23.1142 4.65305 23.2234 4.76363C23.3327 4.87422 23.3941 5.0242 23.3941 5.18059V22.3919C23.3921 22.5329 23.3388 22.6681 23.2443 22.7717C23.1499 22.8752 23.021 22.9399 22.8823 22.9533L22.3878 23.0186V25.2402C23.8737 25.2402 25.5166 24.1675 25.5166 22.3898V5.18495C25.5143 4.46108 25.2281 3.76775 24.7208 3.25733C24.2135 2.74691 23.5268 2.46116 22.8114 2.4629Z"
      :fill="color"
    />
    <path
      d="M18.2784 5.875H5.1869C4.47266 5.87558 3.78788 6.16308 3.28304 6.67431C2.77821 7.18555 2.49463 7.87869 2.49463 8.6014V25.8127C2.49577 26.5349 2.77978 27.2271 3.28443 27.7378C3.78908 28.2484 4.47321 28.5358 5.1869 28.537H18.2784C18.992 28.5358 19.6762 28.2484 20.1808 27.7378C20.6855 27.2271 20.9695 26.5349 20.9706 25.8127V8.6014C20.9706 7.87869 20.687 7.18555 20.1822 6.67431C19.6774 6.16308 18.9926 5.87558 18.2784 5.875ZM4.60414 8.6014C4.60471 8.44518 4.66629 8.29553 4.77545 8.18507C4.88462 8.07461 5.03251 8.0123 5.1869 8.01173H18.2784C18.4327 8.0123 18.5806 8.07461 18.6898 8.18507C18.799 8.29553 18.8605 8.44518 18.8611 8.6014V25.8127C18.8611 25.9691 18.7997 26.1191 18.6904 26.2297C18.5811 26.3403 18.4329 26.4024 18.2784 26.4024H5.1869C5.03234 26.4024 4.88411 26.3403 4.77483 26.2297C4.66554 26.1191 4.60414 25.9691 4.60414 25.8127V8.6014Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
}
</script>