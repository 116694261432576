import _ from "lodash";

var getStorage = function(path) {
  var st = JSON.parse(localStorage.getItem(path));
  var seq = 0;
  if (!st) {
    st = [];
    localStorage.setItem(path + ".seq", seq);
    localStorage.setItem(path, JSON.stringify(st));
  } else {
    seq = localStorage.getItem(path + ".seq") * 1;
  }

  return { storage: st, next: seq + 1 };
};

var setStorage = function(path, storage, seq) {
  if (storage) {
    localStorage.setItem(path, JSON.stringify(storage));
    if (seq) {
      localStorage.setItem(path + ".seq", seq);
    }
  }
};

var mockResource = function(_api, root) {
  var res = function(_api) {
    var api = _api;
    return {
      get: (param) => {
        var id;
        if (!isNaN(param)) {
          id = param;
        } else if (param) {
          id = param.id;
          //query = param.query;
        }
        return new Promise((resolve, reject) => {
          root.$emit("loading", true);
          window.setTimeout(() => {
            var { storage } = getStorage(api);
            if (id) {
              var result = _.find(storage, (item) => item.id == id);
              if (result) {
                resolve(result);
              } else {
                reject("Não encontrado");
              }
            } else {
              resolve(storage);
            }
            root.$emit("loading", false);
          }, 2000);
        });
      },
      save: (obj, id) => {
        return new Promise((resolve, reject) => {
          root.$emit("loading", true);
          window.setTimeout(() => {
            var { storage, next } = getStorage(api);
            if (id) {
              var result = _.find(storage, (item) => item.id == id);
              if (result) {
                _.remove(storage, (item) => item.id == id);
                obj.id = id;
                obj.modificado_em = new Date();
                storage.push(obj);
                setStorage(api, storage);
                resolve(obj);
              } else {
                reject("Não encontrado");
              }
            } else {
              obj.id = next;
              obj.modificado_em = new Date();
              obj.criado_em = new Date();
              storage.push(obj);
              setStorage(api, storage, next + 1);
              resolve(storage);
            }
            root.$emit("loading", false);
          }, 2000);
        });
      },
      silentSave: (obj, id) => {
        return new Promise((resolve, reject) => {
          window.setTimeout(() => {
            var { storage, next } = getStorage(api);
            if (id) {
              var result = _.find(storage, (item) => item.id == id);
              if (result) {
                _.remove(storage, (item) => item.id == id);
                obj.id = id;
                storage.push(obj);
                setStorage(api, storage);
                resolve(obj);
              } else {
                reject("Não encontrado");
              }
            } else {
              obj.id = next;
              storage.push(obj);
              setStorage(api, storage, next + 1);
              resolve(storage);
            }
          }, 2000);
        });
      },
      delete: (id) => {
        return new Promise((resolve) => {
          root.$emit("loading", true);
          window.setTimeout(() => {
            var { storage } = getStorage(api);
            if (id) {
              _.remove(storage, (item) => item.id == id);
              setStorage(api, storage);
            }
            resolve(id);
            root.$emit("loading", false);
          }, 2000);
        });
      },
      patch: (obj, id) => {
        return new Promise((resolve, reject) => {
          root.$emit("loading", true);
          window.setTimeout(() => {
            var { storage } = getStorage(api);
            if (id) {
              var result = _.find(storage, (item) => item.id == id);
              if (result) {
                _.remove(storage, (item) => item.id == id);
                obj.modificado_em = new Date();
                Object.assign(result, obj);
                storage.push(result);
                setStorage(api, storage);
                resolve(obj);
              } else {
                reject("Não encontrado");
              }
            }
            root.$emit("loading", false);
          }, 2000);
        });
      },
    };
  };
  return res(_api);
};

export { mockResource };
