export const UserTypeEnum = {
  ADMINISTRADOR: -1,
  CONSULTOR: 1,
  GERENCIAL_CLIENTE: 2,
  COLABORADOR: 3,
  CLIENTE_ADMINISTRADOR: 4,
  CLIENTE_CONSULTOR: 5,
};

export const AnyAdmin = [UserTypeEnum.ADMINISTRADOR, UserTypeEnum.CONSULTOR];

export const AnyClient = [
  UserTypeEnum.GERENCIAL_CLIENTE,
  UserTypeEnum.COLABORADOR,
];

export const AnySass = [
  UserTypeEnum.CLIENTE_ADMINISTRADOR,
  UserTypeEnum.CLIENTE_CONSULTOR,
];

export const AnyUser = [
  UserTypeEnum.ADMINISTRADOR,
  UserTypeEnum.CONSULTOR,
  UserTypeEnum.GERENCIAL_CLIENTE,
  UserTypeEnum.COLABORADOR,
  UserTypeEnum.CLIENTE_ADMINISTRADOR,
  UserTypeEnum.CLIENTE_CONSULTOR,
];

export const hierarchicalOrder = [
  UserTypeEnum.ADMINISTRADOR, // high order
  UserTypeEnum.CONSULTOR,
  UserTypeEnum.CLIENTE_ADMINISTRADOR,
  UserTypeEnum.CLIENTE_CONSULTOR,
  UserTypeEnum.GERENCIAL_CLIENTE,
  UserTypeEnum.COLABORADOR, // low order
];
