<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 18" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0H20C21.1046 0 22 0.89543 22 2V16C22 17.1046 21.1046 18 20 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0ZM2 2V16H20V2H2ZM6 14H8V8H6V14ZM12 14H10V4H12V14ZM14 14H16V7H14V14Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"],
};
</script>
