export const BeneficiosEnum = {
  "LEI_DO_BEM": 1,
  "LEI_DE_INFORMATICA": 2,
  "LEI_DO_BEM_E_INFORMATICA": 3,
  "CAPTACAO": 4,
};

export const BeneficiosLabels = {
  [BeneficiosEnum.LEI_DO_BEM]: "Lei do Bem",
  [BeneficiosEnum.LEI_DE_INFORMATICA]: "Lei de Informática",
  [BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA]: "Lei do Bem e de Informática",
  [BeneficiosEnum.CAPTACAO]: "Captação",
  "LEI_DO_BEM": "Lei do Bem",
  "LEI_DE_INFORMATICA": "Lei de Informática",
  "LEI_DO_BEM_E_INFORMATICA": "Lei do Bem e de Informática",
  "CAPTACAO": "Captação",
};
