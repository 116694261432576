<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 22C4.92487 22 0 17.0751 0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22ZM11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM16 10H12V5H10V12H16V10Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: ["color"],
}
</script>