import io from "socket.io-client";

const defaultOptions = {
  connection: "http://127.0.0.1:6001",
  options: {},
  timeout: 5000,
  maxRetries: 3,
  retryDelay: 1000,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 10,
  reconnection: true,
  transports: ["websocket", "polling", "flashsocket"],
  query: {
    token: null
  }
}

class Channel {
  /**
   * Initializes a new instance of the Channel class.
   *
   * @param {object} socket - The socket object used for communication.
   * @param {string} channel - The name of the channel to join.
   * @return {undefined}
   */
  constructor(socket, channel) {
    this.socket = socket;
    this.channel = channel;

    //entrar no canal ao criar uma instancia do channel
    this.socket.emit("join", channel);
  }

  /**
   * Listens for events on the channel.
   *
   * @param {string} event - The name of the event to listen for.
   * @param {function} callback - The callback function to execute when the event is received.
   * @return {Channel} The current instance of the Channel class.
   */
  listen(event, callback) {
    /**
     * Registers the callback function to the event specified on the socket.
     *
     * @param {string} event - The name of the event to listen for.
     * @param {function} callback - The callback function to execute when the event is received.
     */
    this.socket.on(event, callback);

    return this;
  }
  /**
   * Leaves the current channel.
   *
   * @return {Channel} The current instance of the Channel class.
   */
  leave() {
    this.socket.emit("leave", this.channel);

    return this;
  }
}

class SocketIOService {

  /**
   * The socket object used for communication.
   * @readonly
   * @memberof SocketIOService
   * @instance
   * @name socket
   * @type {object}
   * @default null
   * @example
   * const socket = socketService.socket;
   */
  socket = null;

  /**
   * The connection URL for the Socket.IO service.
   * @readonly
   * @memberof SocketIOService
   * @instance
   * @name connection
   * @type {string}
   * @default "http://127.0.0.1:6001"
   */
  connection = null;

  /**
   * The options for the Socket.IO service.
   * @readonly
   * @memberof SocketIOService
   * @instance
   * @name options
   * @type {object}
   * @default null
   */
  options = null;

  /**
   * The channels object.
   * @readonly
   * @memberof SocketIOService
   * @instance
   * @name channels
   * @type {object}
   * @default {}
   */
  channels = {};

  /**
   * Initializes a new instance of the class with the given options.
   *
   * @param {object} options - The options for the class.
   * @return {SocketIOService} The new instance of the class.
   */
  constructor(options) {
    const { connection } = options;

    this.connection = connection;
    this.options = options;
  }

  /**
   * Establishes a connection to the Socket.IO service.
   *
   * @param {string} token - The authentication token.
   * @return {SocketIOService} The current instance of the SocketIOService class.
   */
  connect (token = null) {
    this.options.query.token = token;

    if (!this.socket) {
      this.socket = io(this.connection, this.options);
    }

    this.socket.on("connect", () => {
      console.log("Connected to Socket.IO server", this.socket.id);
    });

    this.socket.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
    });

    return this;
  }

  /**
 * Retrieves or creates a channel object for the given channel name.
 *
 * @param {string} channel - The name of the channel.
 * @return {Channel} The channel object.
 */
  channel(channel) {
    if (!this.channels[channel]) {
      this.channels[channel] = new Channel(this.socket, channel);
    }

    return this.channels[channel];
  }
}

export default {
/**
 * Initializes the Socket.IO service and registers it with the Vue app.
 *
 * @param {Object} app - The Vue app instance.
 * @param {Object} options - The options for the Socket.IO service.
 * @return {void}
 */
  install: (app, options) => {
    app.prototype.$socket = new SocketIOService({ ...defaultOptions, ...options });
  }
}