function getRecursiveProperty(key, target) {
  if (typeof key !== 'string') {
    throw new Error('Property key should be a string.');
  }

  const keys = key.split('.');
  return keys.reduce((obj, k) => !!obj && k in obj ? obj[k] : undefined, target);
}

export default getRecursiveProperty;
