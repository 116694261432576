import axios from "axios";
import { isFinite, isObject } from "lodash";

let instance;

if (!instance) {
  instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000
  });
}

export default function(url, config = {}) {
  return {
    async get(param) {
      let _url = url;

      if (isFinite(param)) {
        _url += `/${param}`;
      } else if (isFinite(param.id)) {
        _url += `/${param.id}`;
      }

      if (isObject(param.query)) {
        const query = Object.entries(param.query)
          .map(([key, value]) => `${key}=${value}`)
          .join("&");
        _url += `?${query}`;
      }

      const response = await instance.get(_url, config);
      return response.data;
    },
    async post(data = {}) {
      const response = await instance.post(url, data, config);
      return response.data;
    },
    async patch(id, data = {}) {
      const response = await instance.patch(`${url}/${id}`, data, config);
      return response.data;
    },
    async delete(id) {
      const response = await instance.patch(`${url}/${id}`, config);
      return response.data;
    }
  };
}
