import { uniq } from "lodash";
import { UserTypeEnum } from "@/core/enums/user-types.js";
import getRecursiveProperty from "./getRecursiveProperty";

/** @typedef {import('../core/types/jsdoc').User} User */

/**
 * userHasAccess
 * @param {User} user dados do usuário logado
 * @param {string | string[]} permission a permissão a ser testada, geralmente vindo do VueRouter
 * @returns {boolean}
 */
export function userHasAccess(user, access) {
  if (!access) {
    return false;
  }
  
  let permissions = Array.isArray(access) ? access : [access];
  // Permissão total só deve existir para usuários galapos e inserido pelos devs backends para teste
  // Não faz parte do fluxo normal de permissões, é uma excessão
  const total = getRecursiveProperty('total', user?.empresa?.permissoes) && user.tipo_usuario === UserTypeEnum.CONSULTOR;
  
  if (user.tipo_usuario === UserTypeEnum.ADMINISTRADOR || total) {
    return true;
  }
  
  permissions = permissions.map(permission => {
    const [module, page, action] = `${permission}`.split(".");
    return `${module}.${page || "resource"}.${action || "index"}`;
  });
  permissions = uniq(permissions).sort();

  return permissions.every(permission => {
    return getRecursiveProperty(permission, user?.empresa?.permissoes);
  });
}

/**
 * userHasAccessToAction
 * @param {VueRoute} route dados de uma rota, geralmente a rota atual
 * @param {User} user dados do usuário logado
 * @param {string} action ação, geralmente index, create, update ou delete
 * @returns {boolean}
 */
export function userHasAccessToAction(route, user, action = "index") {
  let { permissions } = route.meta;

  if (!permissions) {
    return false;
  }

  permissions = Array.isArray(permissions) ? permissions : [permissions];
  permissions = permissions.map(permission => {
    const [module, page] = permission.split(".");
    return `${module}.${page}.${action}`;
  });
  permissions = uniq(permissions).sort();

  return userHasAccess(user, permissions);
}
